@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}/* Overall overlay to dim the background */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* or higher if needed */

}

/* The modal box container */
.modalContainer {
  background-color: #1C1C1C; /* or #000 per your design */
  width: 497px;   /* from your dimension overlay */
  max-width: 90%; /* ensures responsive shrink on smaller screens */
  height: 711px;  /* from dimension overlay, optionally use max-height or min-height */
  max-height: 90%;
  border-radius: 16px;
  padding: 24px; /* top/left spacing around content */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalContainer * {
    background-color: #1C1C1C; /* or #000 per your design */
  font-family: var(--font-family);
}
/* Title row with close button */
.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px; /* space between title row and divider */
}

.title {
  font-size: 20px; /* or 24px if you want bigger */
  color: #fff;
  margin: 0;
  font-weight: 500;
}

/* 'X' close button top-right */
.closeBtn {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

/* Thin horizontal divider under title */
.dividerLine {
  width: 100%;
  height: 1px;
  background-color: #333;
  margin-bottom: 28px; /* space before first row */
}

/* Container for all payment rows */
.paymentList {
  flex: 1; /* let this grow/shrink, pushing footer down */
  overflow-y: auto; /* allow scrolling if needed */

}



/* Div that holds icon + method name + details in a column */
.leftPart {
  display: flex;
  align-items: center;
}

/* Payment method icon styling */
.iconStyle {
  width: 40px;
  height: 40px;
  margin-right: 12px; /* space between icon and text */
}



.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.title {
  font-size: 20px; /* or 24px if you prefer */
  color: #fff;
  margin: 0;
}

.closeBtn {
  background: none;
  border: none;
  color: #BBBBBB;
  font-size: 24px;
  cursor: pointer;
}

/* Close button turns red on hover */
.closeBtn:hover {
  color: #FFFFFF;

}

.dividerLine {
  width: 100%;
  height: 1px;
  background-color: #333;
  margin-bottom: 0;
}

.paymentList {
  flex: 1;
  overflow-y: auto; /* scroll if content is too tall */
}

/* Each payment row (icon + text + limit) */
.paymentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*margin-block:28px;*/
  margin-top: 16px;
  margin-bottom: 15px;
  cursor:pointer;
  padding:11px 18px 12px 15.5px;
  border-radius: 12px;
}

.paymentRow:hover {
  background-color: #252525 !important;
}

.paymentRow:hover * {
  background-color: #252525 !important;
}
.leftPart {
  display: flex;
  align-items: center;
}

/* Payment method icon style */
.iconStyle {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.methodName {
  font-size: 16px;
  color: #fff;
  margin-bottom: 4px;
  line-height: 1.2;
  font-weight:600;
}

.details {
  font-size: 14px;
  color: #aaa;
  line-height: 1.4;
}

/* Right side: limit text + "Amount limit" label + expand icon */
.rightPart {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  gap: 12px; /* small space between text block and icon */
}

/* For "Larger than $10,000", etc. */
.limitText {
  font-size: 14px;
  color: #fff;
  margin-bottom: 4px;
  line-height: 1.2;
}

.amountLabel {
  font-size: 12px;
  color: #888;
  line-height: 1.2;
}

/* Expand icon (normal + hover) */
.expandIcon {
  height: 10.558px; /* or 12px if you want slightly bigger */
  width: 5.81px;
  cursor: pointer;
}

/* Divider between payment rows */
.rowDivider {
  margin: 0 0;
  height: 1px;
  background-color: #333;
  margin-left: 15.5px;
  margin-right: 18px;
}

.footerRow {
  font-size: 14px;
  color: #888;
  text-align: center;
  margin-top: 0px;
}

.footerRow a {
color: #6CE3C4;
  text-decoration: underline;
}

