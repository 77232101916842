/* TooltipRight.module.css */

.tooltipWrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  /* 在组件中通过 style={tooltipStyle} 动态计算 left/top/transform */
  background-color: var(--tooltip-bg, #32363D) !important;
  color: #fff;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 9999;
  animation: fadeIn 0.3s ease-in-out;
  text-align: left;
}

.tooltip .arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;

  /*
    当想让箭头“指向左侧”时：
    - border-width:    上  右  下  左
    - border-width:  5px 5px 5px 0  ==> 最右侧的边框是有色的
    - border-color: transparent #32363D transparent transparent

    并且让它位于 tooltip 的左外侧，可通过:
       left: 0;
       transform: translate(-100%, -50%);
    这样“尖尖”就刚好对齐 tooltip 的外边。
  */
  border-width: 5px 5px 5px 0;
  border-color: transparent var(--tooltip-bg, #32363D) transparent transparent;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.learnMoreLink {
  margin-left: 6px;
  color: #6CE3C4;
  text-decoration: underline;
}