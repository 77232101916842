@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}

.container {
    width: 37.29vw;
    height: 36.57vh;
    max-height: 387px;
    margin-top: 15px;
    margin-bottom: 80px;
    max-width: 527px;
    background-color: transparent;
    /*background-color: 537 * 361;*/
}

.mevTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
}

.monthButton {
    padding-block: 4.62px;
    border-radius: 4px;
    color: #CDCFCF;
    background-color: transparent;
    margin-right: 0;
    width: 36px;
    font-family: var(--font-family);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 10.804px; /* 100.465% */
    cursor: pointer;
}

.balanceBox {
    background-color: transparent;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.balanceBox * {
    background-color: #1C1C1C;
}


.balanceBox img {
    margin-left: 13px;
    margin-bottom: 16px;
}

.availableBalance {
    background-color: transparent;

    margin: 0;
    color: #FFF;
    font-family: var(--font-family) !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;

}

.greenGain, .redLoss {

    color: #6CE3C4;
    margin-left: 5px;
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    margin-bottom: 8px;
    border-bottom:1.4px dotted #797979 ;
}

.redLoss {

    color: #FF481F;
}

.customImage {
    .container {
        display: flex;
    }

    .line_stats {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .growth_1000 {
        margin-top: 15px;
        color: var(--Primary-White---100, #FFF);
        font-size: 20px;
        font-family: var(--font-family) !important;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        padding-left: 3vw;
    }

    .growth_percent {
        color: #D6FFE7;
        margin-top: 15px;
        font-size: 20px;
        font-family: var(--font-family) !important;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        padding-right: 3vw;
    }

    .total_return_row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end; /* Aligns content to the right */
        font-family: var(--font-family) !important;
        font-size: 14px !important;
        color: var(--Primary-White---80, rgba(255, 255, 255, 0.80));
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .chartContainer {
        width: 100%;
        height: 100%;
    }

    /* Responsive Adjustments */
    @media (max-width: 768px) {
        .growth_percent, .growth_1000 {
            font-size: 11.993px !important;
            font-family: var(--font-family) !important;
        }

        .total_return_row {
            font-size: 10.993px !important;
            font-family: var(--font-family) !important;
            margin-top: 0px;
        }
    }

    padding-top: 72px;
}

.layout {
    display: flex;
    flex-direction: column;
}


.title {
    margin-top: 36px;
    font-size: 39px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 200;
    line-height: 110%;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(
            108deg,
            #fff 4.39%,
            #e7fff0 29.08%,
            #bfffd9 64.68%
    );
}

.chart1 {
    height: 380px;
    border-radius: 12px;
    border: 1.5px solid var(--Primary-Solid-Stroke-Box, rgba(69, 79, 102, 0.60));
    background: #071112CC;
}

.donut {
    margin-top: 15px;
    /*font-family:*/
}

.plot1 {
    /*height: 380px;*/
    border-radius: 12px;
    border: 1.5px solid var(--Primary-Solid-Stroke-Box, rgba(69, 79, 102, 0.60));
    background: #071112CC;
}

.customLegend {
    display: flex;
    flex-direction: row;
    line-height: 1.25rem;
    text-align: left;
}


.label {
    font-size: 12px;
    font-family: var(--font-family) !important;
    color: rgba(255, 255, 255, 0.8);
}

.chartContainer {
    margin: 25px 0 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    border-radius: 8px;
    /* border: dashed 1px red; */
}


@media (max-width: 768px) {


    .customImage {
        padding-top: 45px;
        /*  height: 43px;*/
        width: 192px;
        height: auto;
    }
}

@media (min-width: 768px) {


    .title {
        margin-top: 8vh;
    }

    .layout {
        margin-top: 62.5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .chart1,
    .plot1 {
        margin: 0;
        padding-top: 15px;
        height: 624px;
        width: 48%;
    }


    .chart1 > div {
        line-height: 25px;
    }

    .plot1 > h1 {
        line-height: 25px;
    }

    .chart1 h1,
    .plot1 span {
        font-size: 20px;
        font-family: var(--font-family) !important;
    }

    .chart1 p,
    .plot1 p {
        font-size: 14px;
        font-family: var(--font-family) !important;
    }

    .customLegend {
        line-height: normal;
        margin-bottom: 15px;
    }

    .label {
        font-size: 16px;
        font-family: var(--font-family) !important;
    }

    .chartContainer {
        position: relative;
        margin: 25px 0 0;
        height: 100%;
        width: 100%;
        padding: 0 50px;
        border-radius: 8px;
    }
}

@media (min-width: 1600px) {
    .title {
        margin-top: 10vh;
    }

    .layout {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


.loadingBox {
    width: 10vw;
    height: 10vw;
    border: 12px solid #102224; /* Light grey */
    border-top: 12px solid #183336; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 100px auto 0; /* Top margin of 30px and centered horizontally */
}

@media (max-width: 768px) {
    .loadingBox {
        width: 45px;
        height: 45px;
        border: 8px solid #102224; /* Light grey */
        border-top: 8px solid #183336; /* Blue */
    }

}

/* Keyframes for the spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.total_return_row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end; /* Aligns content to the right */

    font-size: 14px !important;
    font-family: var(--font-family) !important;
    color: var(--Primary-White---80, rgba(255, 255, 255, 0.80));
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.growth_1000 {

    margin-top: 15px;
    color: var(--Primary-White---100, #FFF);
    font-size: 20px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    padding-left: 3vw;
}

.growth_percent {
    color: #D6FFE7;
    margin-top: 15px;
    font-size: 20px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    padding-right: 3vw;

}

.line_stats {
    padding-top: 15px;
    display: flex;
    flex-direction: row; /* 默认：从左到右 */
    justify-content: space-between;

}

/* 先统一一个“闪烁”类，所有需要闪烁的元素都可以用它 */
.blinkingPlaceholder {
    display: inline-block;
    border-radius: 6px;
    vertical-align: middle;
    animation: blinkAnimation 1s infinite;
    /* 1s周期，无延迟，无方向反转；你也可 infinite alternate 等 */
}

/* 再针对不同的占位符尺寸，单独定义宽高和边距 */
.sizeLarge {
    width: 106px;
    height: 33px;
    margin-bottom: 8px;
    margin-left: 4px;
}

.sizeSmall {
    width: 70px;
    height: 23px;
    margin-bottom: 14.5px;
    margin-left: 14px;
}

/* keyframes：颜色在0%-50%-100%三个阶段切换 */
@keyframes blinkAnimation {
    0%, 100% {
        background-color: #2A2A2A;
    }
    50% {
        background-color: #464646;
    }
}

@media (min-width: 1000px) {
    .lowerMonthButtonContainer {
        display: none !important;
    }
}

@media (max-width: 1000px) {
    .mevTop {
        margin-bottom: 15px;
    }

    .container {
        margin-bottom: 100px;
        max-width: none !important;
        width: 89.41vw;

    }

    .lowerMonthButtonContainer {
        margin-top: 15px !important;
    }

    .topMonthButtonContainer {
        display: none !important;
    }

    .availableBalance {
        font-weight: 500;
        font-family: var(--font-family) !important;
        /*font-size: 22px !important;*/
    }

    .monthButton {
        width: 46px;
        height: 25px;
        line-height: 15.76px;
        display: flex;
        justify-content: center;
    }
}
