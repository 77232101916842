@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}

/* Overall overlay to dim the background */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* or higher if needed */
    overflow-y: auto;
    /*   padding-top: 80px;*/
    /*padding-bottom: 80px;*/
}

/* The modal box container */
.modalContainer {
    background-color: #1C1C1C; /* or #000 per your design */
    width: 497px; /* from your dimension overlay */
    max-width: 90%; /* ensures responsive shrink on smaller screens */
    height: auto !important; /* from dimension overlay, optionally use max-height or min-height */
    max-height: 90%;
    border-radius: 16px;
    padding: 24px 27px 27px 27px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
}

.modalContainer * {
    background-color: #1C1C1C; /* or #000 per your design */
    font-family: var(--font-family);
}

/* Title row with close button */
.headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px; /* space between title row and divider */
}

.title {
    font-size: 20px; /* or 24px if you want bigger */
    color: #fff;
    margin: 0;
}

/* 'X' close button top-right */
.closeBtn {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

/* Thin horizontal divider under title */
.dividerLine {
    width: 100%;
    height: 1px;
    background-color: #333;
    margin-bottom: 10px; /* space before first row */
}


.title {
    font-size: 16px;
    font-weight: 400;

    /* etc. */
}

.label {
    font-size: 12px;
}

.modalContainer input {
    border: 0.6px solid #383838;
    height: 35px !important;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 10px;
    padding-left: 16px;
    color: white;
    font-size: 14px;
}

.modalContainer input:focus {
    border: 0.6px solid #6CE4C4;
}

.modalContainer label {
    font-weight: 400;
}


.confirmBtn {
    width: 100%;
    height: 45px;
    background-color: white !important;
    color: #1A1A1A;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 16.5px */
    margin-top: 11px;
}


.confirmBtn:hover {
    background-color: #DEDBE5 !important;
}

.errorMsg {
    color: red;
    margin-bottom: 10px;
}

.phoneNumber input:first-child {
    text-align: center;
    padding-left: 0;
    width: 19% !important;
}

.phoneNumber input:nth-child(2) {
    width: 79% !important;
}

.greyLine {
    color: #B4B5B6;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 200% */
}
