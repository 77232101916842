@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}
.modalOverlay {
  /* 讓它在螢幕最上方鋪滿並半透明 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* 適度透明度 */
  background: rgba(0, 0, 0, 0.5);
  /* 為確保蓋過其它元素，可設 z-index > 9999 */
  z-index: 9999;

  /* 選擇性：用 flex 置中 modal */
  display: flex;
  justify-content: center;
  align-items: center;
}

.transactionDetailModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: #1c1c1c;
    padding: 24px;
    border-radius: 10px;
    width: 497px;
    height: 671px
}

.transactionDetailModal * {
    background-color: #1c1c1c;
    font-family: var(--font-family) !important;
}


.closeBtn {
    color: #FFFFFF;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: -6px;
    cursor: pointer;
}
.closeBtn:hover{
    color:#BBBBBB;
}
.modalHeader {
    display: flex;
    justify-content: space-between;
    height: 22px;
}

.modalHeader h2 {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    margin-bottom: 0;
}

.dividerLine {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15) !important;
    margin-top: 14px;
    margin-bottom: 14px;
    width: 120px;
}

.dividerLineFull, .dividerLineFull2 {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15) !important;
    margin-top: 14px;
    margin-bottom: 21px;
    width: 100%;
}
.dividerLineFull2{
    margin-bottom: 7px;
    margin-top:0!important;
}
.statusSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.statusText, .statusImageContainer {
    display: flex;
    justify-content: center;
}

.statusText {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    margin-top: 5px;
}

.statusIcon {
    height: 81px;
    width: 81px;
}

.label {
    color: #8D8D8D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 157.143% */
}

.transactionAmounts {
    width: 449px;
    height: 147px;
    display: flex;
    /*justify-content: space-around;*/
    background-color: #32363D !important;
    flex-direction: column;
    border-radius: 10px;
    padding-left: 24px;
    margin-block: 20px;
    padding-top: 12px;
}

.amountRow img {
    height: 27px;
    width: 27px;

}

.amountRow {
    display: flex;
    align-items: center;
}

.amount, .greenAmount {
    font-size: 16px;
    margin-left: 9px;
}

.greenAmount {
    color: #5EC1A7;
}

.transactionAmounts * {
    background-color: #32363D !important;
}
.dividerLineInner{
    height: 1px;
    background-color: #3F3F3F !important;
    margin-top: 11px;
    margin-bottom: 3px;
    width: 100%;
}
.detailRow {
    display: flex;
    justify-content: space-between;
    margin-bottom:12px;
}

.detailLabel {
    color: #BBB;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */

}

.detailValue {
    color: #FFF;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
}

.transactionNotes p{
    color: rgba(255, 255, 255, 0.50);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.confirmBtn {
    border: none;
    border-radius: 10px;
    background-color: white !important;
    height: 45px;
    width: 100%;
    /*margin-top: 40px;*/
    font-family: var(--font-family) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: black;
}

.confirmBtn:hover {
    background-color: #DEDBE5 !important;
}
