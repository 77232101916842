.tooltipWrapper {
  position: relative;
  display: inline-block; /* 使 tooltip 相对于触发元素定位 */
}

/*
   这里用到了强制覆盖法：把背景色设为 !important，
   避免被上层父组件用更高权重的 backgroundcolor !important 影响。
*/
.tooltip {
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  /* 如果没传 css 变量，就用 #32363D 作为默认颜色 */
  background-color: var(--tooltip-bg, #32363D) !important;
  color: #fff;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 9999;
  animation: fadeIn 0.3s ease-in-out;
}

.tooltip .arrow {
  position: absolute;
  top: 100%;   /* 箭头默认在 tooltip 底部，指向下方 */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;

  /*
    把原先的 #32363D 改为 var(--tooltip-bg, #32363D)，
    这样当我们传入新的 backgroundColor 时，
    箭头也会同步改色。
  */
  border-color: var(--tooltip-bg, #32363D) transparent transparent transparent;
  border-top-color: var(--tooltip-bg, #32363D) !important;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.learnMoreLink {
  margin-left: 6px;
  color: #6CE3C4;
  text-decoration: underline;
}