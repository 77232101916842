@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}

.outerContainer {
    display: flex;
    justify-content: center;
    background-color: #04070D;
    width: 100%
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    height: 119px;
    background-color: #04070D;
    width: calc(100% - 6.562vw);
    max-width: 1290px;
    margin-inline: 3.281vw;
}
.container2, .outerContainer2{
        background-color: #0C0F0E;
}
.container2{
    margin-inline: 0;
}
.container a, .userIconContainer {
    background-color: #04070D;
}

.vectorIcon, .menu_mobile_icon {
    display: none;
}

.userIconContainer {
    position: relative; /* Important: so the panel positions relative to this container */
    display: flex;

}

.userIcon {
    width: 153px;
    height: 41px;
    cursor: pointer;
    object-fit: cover;
    background-color: white;
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

}


.userIcon:hover {
    background-color: #DEDBE5 !important;
}

.userIconRedHover:hover {
    background-color: #65676A !important; /* or whichever color you want */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999; /* Below the panel but above everything else */
}

.connectHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1C1C1C;
    height: 20px;

}

.connectHeader h3, .connectOption p {
    background-color: #1C1C1C;
    letter-spacing: normal !important;
    color: #FFF;
    font-family: var(--font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

}


.connectOption p {
    font-size: 16px;
    margin-bottom: 0;
}
.walletType, .emailType{
    background-color: #1C1C1C;
    width: 219px;
    display: flex;
    justify-content: space-between;
}
.emailType{
    width: 245px ;
}
.emailType p {
  max-width: 170px;          /* or any width you prefer */
  white-space: nowrap;       /* prevent text from wrapping to a new line */
  overflow: hidden;          /* hide any text that overflows the container */
  text-overflow: ellipsis;   /* show an ellipsis (...) when text is truncated */
  display: inline-block;     /* required for text-overflow to work properly on inline elements */
}
.walletIcon {
    background-color: #1C1C1C;
    width: 40px;
    height: 40px;
    margin-right: 17px;
    margin-top: 0px;
}

.detect {
    font-size: 12px;
    margin-bottom: 0;
    color: #959595;
    font-family: var(--font-family);
    background-color: #1C1C1C ;
    display: flex;
    align-items: center;

}

.closeBtn {
    color: white;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: -6px;
    cursor: pointer;
}
.closeBtn:hover{
    color:#BBBBBB;
}
/*closebtn when transaction rejected*/
.cancelBtn {
    cursor: pointer;

}

.connectOption {
    background-color: #1C1C1C;
    padding-block: 10px;
    display: flex;
    flex-direction: row;
    width: 352px;
    margin-left: -38px;
    padding-left: 38px;
    cursor: pointer;
    /*margin-bottom:*/
}

.connectOption p {
    line-height: 38px;

}

.connectOption:hover{
    background-color: #32363D;
}
.connectOption:hover * {
    background-color: #32363D;
}
.connectOption:hover p {
    background-color: #32363D;
}

.connectOption:hover img {
    background-color: #32363D !important;
}

/* Base: Closed State (no transition) */
.sidePanel {
    position: absolute;
    top: calc(100% + 10px); /* Just below the icon, add a bit of space (10px) if desired */
    right: 0;
    width: 293px;
    height: 283px;
    background-color: #1C1C1C;
    padding-top: 22px;
    padding-inline: 38px;
    border-radius: 12px;
    z-index: 1000;
    box-shadow: 0px -4px 15px 0px rgba(0, 255, 102, 0.03) inset,
    0px 4px 10px 0px rgba(89, 136, 255, 0.03) inset,
    0px 0px 13px 0px rgba(197, 255, 227, 0.05);
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; /* Prevent interactions when closed */

    /* Notice: NO transition defined here */
    transition: none;
}

/* Open State (with transition) */
.sidePanel--open {
    /* Enable transition only when open */
    transition: opacity 0.3s ease, transform 0.3s ease;

    /* Make sure to override the 'closed' properties */
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}


.sidePanel.open {
    width: 352px;
    height: 447px;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto; /* Re-enable interactions when opened */
}

.openNotConnected {
    height: 448px;
    width: 352px;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.openConnected {
    width: 352px;
    height: 532px;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.dividerLine {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-top: 18.5px;
    margin-bottom: 18.5px;
    width: 120px;
}

.dividerLineFull {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
}

.dividerLineFullLower {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-top: 17px;
    margin-bottom: 16px;
    width: 100%;
}

.or {
    color: #2D3535;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    align-items: center;
    background-color: #1C1C1C;

}

.lineWrapper {
    display: flex;
    flex-direction: row;
    background-color: #1C1C1C;

}

.transactionErrorBoxWapper {
    width: calc(100% - 6.562vw);
    max-width: 1290px;
    margin-inline: 3.281vw;
    display: flex;
    justify-content: right;
    background-color: #04070D;
    z-index: 9999;
}

.transactionErrorBox {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    background: #1C1C1C;
    width: min(calc(50% - 15px), calc(50% - 1.08vw));
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    margin-top: 29.5px;
    margin-bottom: 29.5px;
}

@media (min-width: 1559px) {
    .transactionErrorBoxWapper {
        max-width: 1290px;
        padding-right: 8px;
    }

    .transactionErrorBox {
        width: 628px;
    }
}

.transactionErrorContent {
    background: #1C1C1C;

}

.transactionErrorBox img {
    background: #1C1C1C;
    padding-right: 25px;
    height: 21px;
}

.errorMessage {
    background: #1C1C1C;

    font-family: var(--font-family);
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.errorMessage a {
    background-color: #1C1C1C;
    font-family: var(--font-family);
    color: #2060DF;

}

/* 半透明遮罩：侧边栏打开时盖住内容区域 */
.sideMenuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* 也可改成 100% 如果你布局能撑满窗口 */
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998; /* 比正常内容高，比侧边栏低一点 */
}

/* 侧边栏本体 */
.sideMenuContainer {
    width: 153px;
    height: 100vh;
    background-color: #1e1e1e;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    /* 如果想做滑动效果，可以初始 transform: translateX(-153px);
       打开时再改成 translateX(0)，这里直接默认显现 */
}

/* 每个菜单项的通用样式 */
.menuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    color: #f9f9f9;
    font-weight: 500;
    font-size: 14px;
    padding-left: 20px;
}

/* 菜单项里的图标样式 */
.menuItem img {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    /* 默认给个亮度反转让 svg 显示成白色；视具体图标而定 */
    filter: brightness(0) invert(1);
}

/* 选中时文字 & 图标高亮 */
.menuItem.selected {
    color: #6ce3c4;
}

.menuItem.selected img {
    /* 取消图标的白色滤镜，让它显示原色（比如青绿色） */
    filter: none;
}

/* Mobile Adjustments */
@media (max-width: 1000px) {
    .userIcon {
        margin-right: 18px;
        width: 110px !important;
        height: 21px;
        color: #000;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 7.862px */
        border-radius: 6px !important;
    }
    .userIcon2{
        margin-right: 0;
                height: 30px;

    }

    .vectorIcon {
        margin-left: max(16.7px, calc(50vw - 315px));
        width: 65px;
        height: 20px;
        display: block;
        margin-top: 17px;
    }

    .menu_mobile_icon {
        display: block;
    }
    .menu_mobile_icon2{
        display: none;
    }
    .container {
        height: 63px;
        margin-right: max(16.7px, calc(50vw - 315px));
    }
    .container2{
        margin-right: 0;
    }
    .sidePanel {
        right: 10px;
        /*padding-inline: 29px;*/
    }

    .userIconContainer {
        /*width: 115px;*/
        display: flex;
        align-items: center;
        padding-right: 1px;
    }
    .outerContainer{
        display: flex;
        justify-content: space-between;
    }
}
@media (max-width: 800px) {
.outerContainer2,.container2{
    background-color: #000000;
}
}
@media  (max-width:1000px) {
    .container2{
        width: 120px;
        margin-left: 20px;
    }
}