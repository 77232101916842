@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* 隐藏滚动条，仅适用于webkit浏览器 */
::-webkit-scrollbar {
    width: 0; /* 宽度变0 */
    display: none; /* 有的浏览器用这个也能隐藏 */
}

:root {
    --bg-panel: #04070D;
    --color-white: #F8F8F8;
    --color-light: #B6F2F3;
    --color-green-main: #6CE3C4;
    --color-text-muted: #F8F8F8AA;
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
    --inner-bg-grey: #1c1c1c;
    --border-line: #123034;
    --grey-font: #828787;
}

.outerContainer * {
    font-family: var(--font-family) !important;

}

@media (min-height: 900px) and (min-width: 1000px) {
    .outerContainer {
        height: 100vh !important;
    }
}

.vectorIcon {
    width: 120px;
    height: 36px;
    /*margin-left: 75px;*/
}

.leftPanel {
    width: 189px;
    min-width: 189px;
    background-color: var(--bg-panel);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 44px;
    border-right: 1px solid #2a2c30;
}

.leftPanel > * {
    background-color: var(--bg-panel);

}

.leftOption {
    font-size: 14px;
    font-family: var(--font-family);
    height: 17px;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: center;
    display: flex;
    font-style: normal;
    font-weight: 500;
    padding-left: 35px;
    cursor: pointer;
}

.leftOption:hover {
    color: rgba(255, 255, 255, 0.8);
}

.leftOptionUl {
    padding-left: 0;
}

.leftOption:first-child {
    /*width: 189px;*/

    margin-top: 71px;
    background-color: #32363D;
}

.leftOption:nth-child(2), .leftOption:nth-child(3) {
    margin-top: 10px;

}

.rightPanel {
    background-color: var(--bg-panel);
    width: calc(100vw - 189px);
}

.topLeftImageWrapper {
    margin-bottom: 30px;

}

.topLeftImageWrapper a {
    background-color: var(--bg-panel);

}


.leftMenu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 188px;
}

.leftMenu li {
    margin: 20px 0;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 14px;
    transition: color 0.2s ease;
    background-color: var(--bg-panel);
}

li {
    background-color: var(--bg-panel);
}

.leftMenu li:hover {
    color: rgba(255, 255, 255, 0.8);
}

.activeMenuItem {
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
}

.container {
    display: flex;
    color: var(--color-white);
    min-height: 82.978vh;
    padding-left: 40px;
    padding-right: 54px;
    padding-bottom: 49px;
    gap: min(2.08vw, 30px);
    box-sizing: border-box;
    background-color: #04070D;
    justify-content: center;
}

.leftContainer > * {
    background-color: var(--inner-bg-grey);
}

.withdrawSectionMiddle > *, .tabs {
    background-color: var(--inner-bg-grey);
}

.leftContainer {
    min-height: 760px;
    background-color: var(--inner-bg-grey);
    border-radius: 18px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 38px;
    backdrop-filter: blur(45px);
    width: 39.24vw;
    max-width: 630px;
    max-height: 819px;
}

.rightContainer {
    background-color: #04070D;
    border-radius: 18px;
    backdrop-filter: blur(45px);
    max-width: 630px;
    max-height: 819px;
}


.rightContainer {
    flex: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 43.75vw;
    min-width: 364px;
}

.balanceSection {
    background-color: var(--inner-bg-grey);
    border-radius: 10px;
    max-width: 537px;
}

.balanceBox {
    display: flex;
    flex-direction: row;
    background-color: var(--inner-bg-grey);
    align-items: flex-end;
}

.balanceBox img {
    margin-left: 13px;
    margin-bottom: 16px;
}

.availableBalance {
    background-color: var(--inner-bg-grey);
    margin: 0;
    color: #FFF;
    font-family: var(--font-family) !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.6px;

}

.greenGain {
    color: var(--color-green-main);
    margin-left: 5px;
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: var(--inner-bg-grey);
    margin-bottom: 8px;
}

.chartWrapper {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-muted);
    font-size: 14px;
    font-family: var(--font-family) !important;
    background-color: var(--inner-bg-grey);
}

.usdiAPY {
    text-align: right;
    background-color: var(--inner-bg-grey);
    justify-content: right;
    display: flex;
}

.innerUsdiApy {
    justify-content: space-between;
    display: flex;
    background-color: var(--inner-bg-grey);
    gap: 58px;
}

/* used for adjust left section's balance and apy gap*/
@media (max-width: 1250px) and (min-width: 1000px) {
    .innerUsdiApy {
        gap: 48px;
    }

}

@media (max-width: 1150px) and (min-width: 1000px) {
    .innerUsdiApy {
        gap: 38px;
    }
}

.innerUsdiApy > * {
    background-color: var(--inner-bg-grey);

}

.usdiItemLeft > * {
    background-color: var(--inner-bg-grey);

}

.usdiLabel {
    color: var(--grey-font);
    font-family: var(--font-family) !important;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.usdiAPYtitle {
    color: var(--grey-font);
    font-family: var(--font-family) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    background-color: var(--inner-bg-grey);
    /*margin-top: -25px;*/
}

.usdiBox {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;
}

.usdiInfo {
    padding-right: 0;
    padding-left: 0;
    background-color: var(--inner-bg-grey);
    border-radius: 10px;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
}

.usdiInfo * {
    background-color: var(--inner-bg-grey);
}

.usdiItemLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
    background-color: var(--inner-bg-grey);
    padding-top: 30px;
}

.usdiItemLeft p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.80);
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
}

.usdiItemRight {
    display: flex;
    flex-direction: column;
    gap: 28px;

}

.usdiItemRight h5 {
    text-align: right;
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
    background: var(--color-green-main);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 0;
    margin-bottom: 0;
}

.usdiImage {
    width: 33px;
    height: 33px;
    margin-right: 13px;
}

.usdiHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin-bottom: 10px;*/
    margin-top: 13px;
    background-color: var(--inner-bg-grey);

}

.usdiTitle {
    color: rgba(255, 255, 255, 0.80);
    font-size: 20px !important;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
    margin-left: -27.40vw; /* need to change for responsive design*/
}

.usdiStats {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: var(--font-family) !important;
    background-color: var(--inner-bg-grey);
    gap: 45px;
}

.usdiStats h4 {
    color: #FFF;
    font-family: var(--font-family) !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 91.667% */
    background-color: var(--inner-bg-grey);
}

.usdiStats p {
    color: var(--grey-font);
    font-family: var(--font-family) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    background-color: var(--inner-bg-grey);
    margin-bottom: 0 !important;
    border-bottom:1.4px dotted #797979 ;

}
.leftBottomStats {
    color: var(--grey-font);
    font-family: var(--font-family) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    background-color: var(--inner-bg-grey);
    margin-bottom: 0 !important;
    border-bottom:1.4px dotted #797979 ;
}
.usdiValue {
    background-color: var(--inner-bg-grey);

    color: var(--grey-font);
    font-family: var(--font-family) !important;
    font-size: 12px;
    font-weight: 500;
    text-align: right;
}

.usdiValue2 {
    font-family: var(--font-family) !important;
    background-color: var(--inner-bg-grey);
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    text-align: right;


}

.usdiDataWrapper {
    background-color: var(--inner-bg-grey);
}

.usdiStats div p:first-child {
    color: var(--color-text-muted);
    margin-bottom: 5px;
}

.lineSeparator, .lineSeparator2, .lineSeparator3 {
    width: 100%;
    height: 1px;
    background-color: #333; /* Adjust to match the desired gray shade */
    /*margin-top: 2.431vh; !* Adjust spacing as needed *!*/
    margin-top: 20px; /* Adjust spacing as needed */
    margin-bottom: 43px;
}

.lineSeparator2 {
    margin-top: 9px;
    margin-bottom: 21px;
}

.lineSeparator3 {
    margin-top: 19px;
    margin-bottom: 34px;
}

.qrContainer {
    display: flex;
    justify-content: space-between;
    background-color: var(--inner-bg-grey);

}

.QRCODE {
    display: flex;
    align-items: center;
    justify-content: right;
}

.qrTextContainer {
    padding-top: 27px;

}

.copyContainer {
    display: flex;
    flex-direction: row;
    gap: 89px;
}

.copyBtn2 {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-top: 3px;
}

.qrContainer * {
    background-color: var(--inner-bg-grey);
    font-family: var(--font-family);

}

.qrContainer button {
    border: none;
    border-radius: 10px;
    background-color: white;
    height: 41px;
    width: 218px;
    margin-top: 40px;
    font-family: var(--font-family) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: black;
}

.walletCreateProgress {
    border-radius: 10px;
    background-color: white;
    height: 41px;
    width: 218px;
    margin-top: 40px;
}


.qrContainer button:hover {
    background-color: #DEDBE5;
}

.qrText1 {
    color: white;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 10px;
}


.qrText2 {
    color: white;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;

}

.qrGreytext, .qrGreytext1, .qrGreytext2, .qrGreytext3 {
    font-size: 18px;
    color: #8D8D8D;
    margin-bottom: 40px;
    line-height: 18px;
}

.qrGreytext1, .qrGreytext2 {
    margin-bottom: 0;
}

.qrGreytext3 {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 17px;
}

.QRCODE {
}

.bottomContainer, .bottomContainer1 {
    background-color: var(--inner-bg-grey);
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.bottomContainer *, .bottomContainer1 * {
    background-color: var(--inner-bg-grey);
    font-family: var(--font-family);
    color: #8D8D8D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bottomContainer img, .bottomContainer1 img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
}

.bottomContainer1 img {
    width: 13px;
    height: 13px;
    margin-right: 13px;
}

.bottomContainer1 {
    display: flex;
    align-items: center;
}

/* Tabs */
.tabs {
    width: max(240px, 45%);
    height: 50px;
    display: flex;
    /*padding: 10px;*/
    /*gap: 10px;*/
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    border-radius: 12px;
    /*border: 1.5px solid var(--border-line);*/
    /*background: #041812;*/
    /*box-shadow: 0px -4px 30px 0px rgba(0, 255, 102, 0.03) inset, 0px 4px 30px 0px rgba(89, 136, 255, 0.03) inset, 0px 0px 13px 0px rgba(197, 255, 227, 0.05);*/
    backdrop-filter: blur(45px);
}

.tabs button {
    /*  display: flex;          !* 让内部元素能用 flex 居中 *!*/
    /*align-items: center;    !* 垂直居中 *!*/
    /*justify-content: center;!* 水平居中 *!*/
    position: relative;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    height: 50px;
    width: 50%;
    border-radius: 12px;
    /*transition: all 0.2s ease-in-out;*/
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 22px */
}


.tabText {
    color: #333333 !important;
    font-size: 20px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* ~22px */
    position: absolute; /* Let it overlap */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 100; /* Above any background/pill */
    transition: color 0.6s ease;
}

.nonSelectTabText {
    color: #D2D2D2 !important;
    transition: color 0.2s ease;
}

.nonSelectTabText:hover {
    color: rgba(255, 255, 255, 0.6) !important;
}

.tapPill {
    position: absolute; /* from 'absolute' */
    top: 0; /* from 'inset-0' */
    right: 0; /* from 'inset-0' */
    bottom: 0; /* from 'inset-0' */
    left: 0; /* from 'inset-0' */
    background: #FDFEFE !important;
    border-radius: 12px; /* from 'rounded-full' */
    width: 100%; /* from 'w-100' (assuming 100px) */
    height: 50px; /* from 'h-50' (assuming 50px) */
    z-index: 10; /* from 'z-99' */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}


.tapPill:hover {
    background: #DEDBE5 !important;

}

.depositSection {
    /*min-height: 714px;*/
    flex: 1;
    padding-top: -20px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 18px;
    background-color: var(--inner-bg-grey);
    max-width: 630px;


}

.swapSection, .withdrawSection {
    height: 100%;
    background-color: var(--bg-panel);
    flex: 1;
}

.withdrawSection {
    background-color: var(--bg-panel) !important;
}

.withdrawSectionMiddle, .swapSection {
    height: 370px;
    padding-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 18px;
    background-color: var(--inner-bg-grey);

}


.withdrawSection button {
    border: none;
    border-radius: 10px;
    background-color: white;
    height: 60px;
    width: 100%;
    margin-top: 40px;
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: black;
}

.withdrawSection button:hover {
    background-color: #DEDBE5;
}

/* 包住 input 与伪placeholder */
.customInputWrapper {
    position: relative;
    width: 100%;
    background: #1c1c1c;
    /*margin-inline: 35px;*/
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 127px;
    gap: 30px;
}

.customInputWrapper2 {
    height: 127px;
}

.addPaymentButton {
    background-color: #32363D !important;
    width: 86%;
    height: 45px;
    margin-left: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.6px */
    border-radius: 12px;
    margin-top: 6px;
    cursor: pointer;
    margin-bottom: 24px;
}

.addPaymentButton:hover {
    background-color: #65676A !important;

}

/* 伪 placeholder：绝对定位在 input 之上，当 input 没有内容时显示 */
.fakePlaceholder {
    position: absolute;
    top: 50%;
    left: 0.09rem; /* 根据需要做微调 */
    transform: translateY(-50%);
    pointer-events: none; /* 不可点击 */
    width: 50%;
    background: #1c1c1c;
}

.fakePlaceholderFont {
    font-family: var(--font-family) !important;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* 让用户选中文本时变绿色，可以加在同一个class里 */
.withDrawBig::selection {
    background: rgba(108, 227, 196, 0.3); /* 半透明绿色背景 */
    color: #6CE3C4;
}

.withDrawBig::-moz-selection {
    background: rgba(108, 227, 196, 0.3);
    color: #6CE3C4;
}

.withDrawBig, .withDrawGrey {
    color: #FFF;
    font-family: var(--font-family) !important;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: var(--inner-bg-grey);
}

.customInputWrapper {
    display: flex;
    align-items: center;
}

.withDrawBig input {
    background: #1c1c1c !important;
    border: none !important;
    caret-color: #6CE3C4;
    color: white !important;
}


.withDrawBig[type="number"]::-webkit-inner-spin-button,
.withDrawBig[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.withDrawBig[type="number"] {
    -moz-appearance: textfield;
}

.withDrawGrey {

    color: #828787;
    margin-left: 10px;
    background-color: var(--inner-bg-grey);
}

.withDrawAmount {
    background-color: var(--inner-bg-grey);
    color: #828787;
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */

}

.withDrawItemLeft {
    display: flex;
    flex-direction: row;
    gap: 15px;
    background-color: var(--inner-bg-grey);

}

.withDrawItemRight {
    /*margin-left: 210px;*/
    justify-content: space-around;
    text-align: right;
    background-color: var(--inner-bg-grey);

}

.withDrawItemLeft h4, .withDrawItemLeft h5, .withDrawItemRight h5, .payWithValue {
    color: #FFF;
    font-family: var(--font-family) !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 110% */
    margin-bottom: 0;
    background-color: var(--inner-bg-grey);

    /*align-items: center !important;*/
}


.payWithValue {
    font-size: 16px;
}

.withDrawItemLeft h5, .withDrawItemRight h5 {
    font-size: 16px;
    color: #828787;
    background-color: var(--inner-bg-grey);


}

.withdrawImg {
    width: 55px;
    height: 55px;
    background-color: var(--inner-bg-grey);
}

.withdrawImg2 {
    width: 50px;
    height: 50px;
    background-color: var(--inner-bg-grey);
}

.mintContainer {
    height: 583px;
    padding-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 18px;
    padding-bottom: 20px;
    background-color: var(--inner-bg-grey);
    width: 100%;
    /*box-shadow: 0px -3.587px 26.905px 0px rgba(0, 255, 102, 0.03) inset, 0px 3.587px 26.905px 0px rgba(89, 136, 255, 0.03) inset, 0px 0px 11.659px 0px rgba(197, 255, 227, 0.05);*/
    backdrop-filter: blur(40.35714340209961px);
}

.methodItemLeft img, .methodItemLeft h4, .methodItemRight, .methodItemRight h5, .arrow, .otherMethods h3 {
    background-color: var(--inner-bg-grey);
    font-family: var(--font-family) !important;
}


.methodItem:hover,
.methodItem:hover *,
.methodItem2:hover,
.methodItem2:hover * {
    background-color: #252525 !important;
}

.tokenSelectorContent:hover,
.tokenSelectorContent:hover * {
    background-color: #252525 !important;
}

/* If `.noHover` is present, override that hover rule */
.noHover:hover,
.noHover:hover * {
    background-color: #2b3336 !important;
}

.arrow {
    padding-left: 24px;
    display: flex;
    align-items: center;
}

.arrowDown {
    padding-left: 15.8px;
    background-color: var(--inner-bg-grey);

}

/*.withdrawSection,*/
.depositSection h3,
.withdrawSection h3,
.swapSection h3 {
    margin-bottom: 15px;
    color: #FFF;
    font-size: 20px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: var(--inner-bg-grey);

}

/* Network List */
.networkList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.networkItem {
    display: flex;
    justify-content: space-between;
    background: var(--bg-darker);
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
    align-items: center;
}

.networkItem:hover {
    background: #042f44;
}

.networkItem div {
    display: flex;
    flex-direction: column;
}

.networkName {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: var(--font-family) !important;
}

.networkAddress {
    display: block;
    font-size: 12px;
    font-family: var(--font-family) !important;
    color: var(--color-text-muted);
}

.walletAddress {
    display: flex;
    flex-direction: column;
    padding-bottom: 3px;
    background-color: var(--inner-bg-grey);
}

.copyBtn {
    position: relative;
    border: none;
    color: #001020;
    margin-top: 17px;
    padding: 8px;

    /*margin-top:20px;*/
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    font-family: var(--font-family) !important;
    background-color: var(--inner-bg-grey);
    overflow: hidden; /* Ensures highlight stays within the button */
}

.copyBtn img {
    width: 24px;
    height: 24px;
}

/* Highlight element */
.copyBtn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 0.2s ease, opacity 0.3s ease;
}

/* Show highlight when button is pressed */
.copyBtn:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

/* Keep highlight if button remains focused after click */
.copyBtn:focus::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.copyImg {
    background-color: var(--inner-bg-grey);
    cursor: pointer;
    width: 13.5px;
    height: 13.5px;
}

.cryptoNetwork {
}

.otherMethods {
    margin-top: 40px;
    background-color: var(--inner-bg-grey);
}

.otherMethods2 {
    margin-top: 55px;
}

.otherMethods h4 {
    color: #FFF;
    font-size: 20px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    background-color: var(--inner-bg-grey);

}

.otherMethods h5 {
    color: rgba(255, 255, 255, 0.50);
    text-align: right;
    font-size: 16px;
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    margin-bottom: 0;
    background-color: var(--inner-bg-grey);

}

.methodItemLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: var(--inner-bg-grey);

}

.verticalLine {
    margin-left: 23.6px;
    height: 15px;
}

.methodItemRight {
    display: flex;
    flex-direction: column;
    /*gap: 6px;*/
    /*height:20px;*/
}

.methodItem, .methodItem2 {
    padding-inline: min(35px, 2.36vw);
    margin-left: max(-35px, -2.36vw);
    /*max-width: 630px;*/
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: var(--font-family) !important;
    color: var(--color-white);
    align-items: center;
    background-color: var(--inner-bg-grey);
    cursor: pointer;
    /*width: 100%;*/
    padding-block: 10px;
    width: min(calc(100% + 70px), calc(100% + 4.72vw));
    margin-top: 45px;
}

.methodItem2 {
    margin-top: 0;
}


.methodItem span {
    margin-right: 10px;
}

/* For better spacing in columns */
.methodItem span:last-child {
    margin-right: 0;
}

.tokenSelectorModal3, .tokenSelectorModal2, .tokenSelectorModal {
    position: absolute;
    top: 91px; /* positions it just below the parent container */
    left: 20px; /* align left edges */
    z-index: 999; /* ensure it appears on top */
    background: #060F11;
    opacity: 1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #2B3242;
    /*padding-inline: 12px;*/
    padding-left: 0px;
    padding-right: 0px;
    width: 90%;
    height: 173px;
    border-radius: 15px;
}

.tokenSelectorModal2 {
    top: 184px;
    height: 325px;
}

.tokenSelectorModal3 {
    top: 396px;
}

.tokenSelectorTitleContainer {
    display: flex;
    height: 25px;
    justify-content: space-between;
    background: #060F11;
    margin-left: 14px;
    margin-right: 30px;

}

.tokenSelectorTitle {
    font-weight: 600;
    font-size: 12px;
    font-family: var(--font-family);
    color: var(--grey-font);
    background: #060F11;
    padding-top: 8px;

}

.tokenSelectorContentContainer {
    cursor: pointer;
    margin-top: 6px;
}

.tokenSelectorContent {
    background: #060f11;
    display: flex;
    justify-content: space-between;
    padding-block: 7px;
    padding-left: 14px;
    padding-right: 30px;
}

.tokenSelectorContentLeft {
    background: #060f11;

    display: flex;
}

.tokenSelectorContentLeft h4, .tokenSelectorContent h5 {
    background: #060f11;
    font-family: var(--font-family);
}

.tokenSelectorContentLeft h4 {
    font-size: 18px;
    font-weight: 600;
}

.tokenSelectorContentLeft h5 {
    font-size: 12px;
    color: var(--grey-font);

}

.selectorImg {
    background: #060f11;
    width: 55px;
    height: 55px;
    margin-right: 28px;
}

.tokenSelectorWalletContainer, .tokenSelectorContentRight {
    background: #060f11;
}

.tokenSelectorWalletContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.tokenSelectorContentRight {
    font-family: var(--font-family) !important;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    background: #060f11;
    text-align: right;
    height: 21.59px;
    margin-top: 5px;
    margin-bottom: 0;
}


.tokenSelectorContentRightGrey, .tokenSelectorContentRightGreen {
    font-family: var(--font-family) !important;
    color: var(--grey-font);
    font-size: 12px;
    font-weight: 400;
    background: #060f11;
}

.tokenSelectorContentRightGreen {
    color: #54A792;
    text-align: right;
}

.tokenSelectorContentRightGrey:hover {
    color: white;
}

.tokenSelectorContentRightGreyIMG {
    width: 9px;
    height: auto;
    background-color: #060F11;
    margin-left: 8px;
}

.tokenSelectorContentRightGreyIMG2 {
    width: 20px;
    height: auto;
    background-color: #060F11;
    margin-left: 8px;
}

/* Withdraw & Swap Placeholder */
.withdrawSection p,
.swapSection p {
    font-size: 14px;
    font-family: var(--font-family) !important;
    color: var(--color-text-muted);
    text-align: center;
    margin-top: 50px;
}

@media (max-width: 1440px) {
    .container {
        /*padding-left: 2.778vw;*/
        /*padding-right: 3.784vw;*/
        padding-inline: 3.281vw;
    }

    .leftContainer {
        padding-left: 3.472vw;
        padding-right: 3.472vw;
    }

    .withdrawSectionMiddle {
        padding-inline: 2.36vw;
        padding-right: 2.36vw;
    }
}

.maxButton {
    position: absolute !important;
    right: 10px !important;
    top: 25% !important;
    transform: translateY(-50%) !important;
    background: #32363D !important;
    color: #fff !important;
    border: none !important;
    border-radius: 12px !important;
    padding: 4px 8px !important;
    cursor: pointer !important;
    width: 58px !important;
    height: 41px !important;
}

.maxButton:hover {
    background-color: #65676A !important;
}

/*modal*/
/* ==== Modal Overlay (dark screen behind modal) ==== */
/*.modalOverlay {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background: rgba(0, 0, 0, 0.5); !* semi-transparent black *!*/
/*    z-index: 999; !* behind the modal content but in front of everything else *!*/
/*}*/
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* 半透明黑色 */
    z-index: 999; /* 确保覆盖内容 */
}

/* ==== Modal Container ==== */
.modalContainer {
    position: fixed;
    /* center horizontally, push a bit down from top */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 90vw;
    height: 280px;
    background-color: #1c1c1c; /* or your dark color */
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    z-index: 1000; /* in front of the overlay */
}

.modalContainer * {
    background-color: #1c1c1c; /* or your dark color */
    font-family: var(--font-family);
}

/* Close (X) button in top-right corner */
.closeButton {
    position: absolute;
    top: 24px;
    right: 24px;
    background: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 24px;
    cursor: pointer;
}

.closeButton:hover {
    color: #BBBBBB;
}

/* Title: "Transaction detail" */
.modalTitle {
    font-size: 22px;
    color: #fff;
    margin: 0; /* If there's default heading margin */
    align-self: flex-start;
    padding-left: 0; /* Already within the container's 24px */
}

/* Horizontal divider below the title */
.modalDivider {
    width: 100%;
    margin: 20px 0;
    border: none;
    border-top: 1px solid #444;
}

/* The green check mark icon in the center */
.checkIcon {
    width: 60px; /* Based on your screenshot; adjust as needed */
    height: 60px;
    margin-bottom: 20px;
}

/* "Request Received" heading */
.requestReceivedTitle {
    font-size: 18px;
    color: #fff;
    margin: 0 0 12px 0;
}

/* Additional text under "Request Received" */
.requestReceivedText {
    font-size: 14px;
    color: #ccc;
    text-align: center;
    line-height: 1.5;
}

.blinkingPlaceholder {
    display: inline-block;
    width: 65px; /* a square roughly the size of text height */
    height: 22px;
    border-radius: 4px;
    vertical-align: middle;
    /* The blinking animation */
    animation: blinkAnimation 1s infinite;
}

/* keyframes to blink between two background colors */
@keyframes blinkAnimation {
    0%, 100% {
        background-color: #2A2A2A;
    }
    50% {
        background-color: #464646;
    }
}

@media (max-height: 840px) {
    .otherMethods {
        margin-top: 30px;
    }
}

@media (max-width: 1000px) {
    .mintContainer {
        padding-left: 16px;
        padding-right: 25px;
        padding-bottom: 0;
        height: 580px;
        padding-top: 23px;
    }

    .lineSeparator2 {
        margin-top: 13px;
    }

    .container {
        flex-direction: column;
        padding-inline: 0;
        display: flex;
    }

    .leftPanel {
        display: None;
    }

    .rightPanel {
        width: 100%;
    }

    .leftContainer {
        margin-left: 4%;
        width: 92%;
        border-radius: 13px;
        padding-left: 25px;
        padding-right: 25px;
        background-color: #1C1C1C;
        box-shadow: none;
        min-height: 500px;
        padding-bottom: 25px;

    }

    .usdiDataWrapper p {
        margin-bottom: 20px;
    }

    .usdiDataWrapper {
        text-align: center !important;

    }

    .usdiDataWrapper2 {
        text-align: center !important;
        /*padding-left: 9px  !important;*/

    }

    .usdiDataWrapper3 {
        text-align: center !important;
        /*padding-left: 9px  !important;*/

    }

    .usdiStats {
        gap: 55px;
    }

    .usdiAPYtitle {
        margin-top: 10px;
    }

    .rightContainer {
        margin-left: 4%;
        width: 92%;
        margin-bottom: 63px;

    }

    .depositSection {
        padding-bottom: 34px;
        padding-left: 16px;
        padding-right: 17px;
        border-radius: 13px;
    }

    .copyBtn {
        padding-right: 0;
    }

    .tabs {
        margin-top: 25px;
        margin-bottom: 28px;
    }

    .tabs button {
        font-size: 16px;
    }

    .methodItemLeft img {
        width: 36px
    }

    .methodItemLeft {
        gap: 12px;
    }

    .walletAddress h4, .methodItemLeft h4 {
        font-size: 16px;
    }

    .methodItemRight h5 {
        font-size: 12px;
    }

    .withdrawSectionMiddle {
        padding-inline: 20px;
    }

    .leftContainer {
        padding-top: 0;
        min-width: 364px;
    }

    .chartWrapper {
        height: 440px;
    }

    .usdiHeader {
        margin-top: 20px;
    }

    /*.usdiAPYtitle {*/
    /*margin-bottom: 10px !important;*/
    /*}*/
    .usdiItemRight {
        gap: 30px;
    }
}

@media (max-width: 1400px) {
    /*.qrContainer2{*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*}*/
}

@media (max-width: 1296px) {
    .copyContainer {
        gap: 50px;
    }
}

@media (max-width: 1251px ) {
    .qrText1 {
        font-size: 23px;
        line-height: 23px;
    }

    .qrText2, .qrGreytext2, .qrGreytext {
        font-size: 16px;
        line-height: 16px;
    }

    .copyContainer {
        gap: 30px;
    }

    .qrCode {
        width: 170px;
    }
}

@media (max-width: 1144px ) {
    .mintContainer {
        height: 558px !important;
    }
}

@media (max-width: 1166px) {
    .leftPanel {
        width: 161px !important;
        min-width: 161px;
    }

    .leftOption {
        margin-left: -14px !important;
    }

    .leftMenu, .leftMenu ul {
        width: 160px !important;
    }

    .container {
        padding-left: 3.28vw;
        padding-right: 3.28vw;
    }

    .usdiStats p {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: normal;
    }

    .customInputWrapper {
        gap: 15px;
    }

}

/*left container and right container is not centered*/
@media (max-width: 1000px) {
    .leftContainer, .rightContainer {
        margin-left: max(16.7px, calc(50vw - 315px));
        margin-right: 16.7px;
    }

    .container {
        padding-inline: 0 !important;
    }
}

.QRCODE {
    width: 195px;
    height: 195px;

}

@media (max-width: 1000px) {
    .bottomContainer *, .bottomContainer * {
        font-size: 12px;
    }

    .QRCODE {
        width: 121px;
        height: 121px;
    }

    .qrText1 {
        font-size: 18px;
    }

    .qrText2 {
        font-size: 16px;

    }

    .qrGreytext, .qrGreytext2 {
        font-size: 14px;
    }

    .copyContainer {
        gap: 30px;
    }

    .qrTextContainer {
        padding-top: 0px;
    }

    .qrContainer {
        margin-block: 30px;
        align-items: center;
    }

    .withDrawItemLeft h4 {
        font-size: 16px;
    }

    .withDrawItemLeft h5 {
        font-size: 14px;
    }

}