@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
    --grey-text: #8D8D8D;
}

.mainContainer {
    display: flex;
    flex-direction: row;
    background-color: var(--bg-panel);
    min-height: 100vh;
    width: 100vw;
}

.RightPanelOuterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--bg-panel);
}

.rightPanel {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-panel);
    width: calc(100vw - 189px);
}

.rightPanel * {
    font-family: var(--font-family);
}

.outerAccountPageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.accountTitle {
    background-color: var(--bg-panel);
    font-size: 18px;
    margin-bottom: 23px;
    margin-left: max(20px,calc(50vw - 499px));
    font-weight: 500;
}

.accountPage {
    width: 800px;
    background-color: var(--inner-bg-grey) !important;
    /*height: 870px;*/
    padding-inline: 39px;
    margin-left: max(20px, calc(50vw - 499px));
    margin-right: 20px;
    border-radius: 12px;
    padding-top: 24px;
    margin-bottom: 52px;

}

/* Instead of .accountPage * { ... } do: */
.accountPage *:not(.blinkingPlaceholder) {
  background-color: var(--inner-bg-grey);
}
/*Could be duplicate*/
.userInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 44px;
}

.userInfoContent {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userWallet {
    font-size: 16px;
}


.greyText, .addPaymentInfo {
    font-size: 14px;
    color: var(--grey-text);
}

.editButton, .removeButton {
    width: 58px;
    height: 41px;
    background-color: #32353C !important;
    font-size: 14px;
    border-radius: 15px;
    color: white;
    border: none;
    cursor: pointer;
}

.removeButton {
    width: 90px;
}

.editButton:hover, .removeButton:hover {
    background-color: #65676A !important;
}

.divider {
    margin-block: 22px;
    /*background: #3E3E3E;*/
    stroke-width: 0.896px;
stroke: rgba(255, 255, 255, 0.15);
}

.divider2 {
    margin-top: 28px;
    margin-bottom: 28px;
        /*background: #3E3E3E;*/
    stroke-width: 0.896px;
stroke: rgba(255, 255, 255, 0.15);

}

.addPaymentButton {
    height: 45px;
    background-color: #FFF !important;
    color: #1A1A1A;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 16.5px */
    width: 490px;
    margin-left: 116px;
}

.addPaymentButton:hover {
    background-color: #DEDBE5 !important;
}

/*.contactInfoContainer{*/
/*    height: 289px;*/
/*}*/
/*End of could be duplicate*/

.contactInfoContainer h2, .paymentMethodsContainer h2 {
    font-size: 18px;
    margin-bottom: 40px;
}

/*.contactInfoContainer h2 {*/
/*    margin-bottom: 29px;*/
/*}*/

.addPaymentInfo {
    margin-top: 0px;
    margin-bottom: 64px;
}

.addPaymentInfo img {
    margin-right: 6px;

}


/* 背景遮罩 */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* 半透明黑色 */
    z-index: 999; /* 确保覆盖内容 */
}

/* Modal 容器 */
.modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--inner-bg-grey) !important;
    width: 497px; /* 根据示例给出的宽度 */
    min-height: 230px; /* 根据示例给出的高度 */
    border-radius: 12px;
    padding: 24px 24px 34px; /* 上右下左 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000; /* 高于遮罩层 */
    display: flex;
    flex-direction: column;
    color: #fff;
    font-family: var(--font-family);
}

.modalContainer * {
    background-color: var(--inner-bg-grey) !important;
    font-family: var(--font-family);

}

/* 右上角关闭按钮 */
.modalCloseBtn {
    position: absolute;
    top: 24px;
    right: 24px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
}

.modalCloseBtn:hover {
    color: #BBBBBB;
}

/* Modal 标题 */
.modalTitle {
    font-size: 18px;
    margin-bottom: 14px; /* 标题和分割线的间距 */
}

/* 标题下的分割线 */
.modalDivider {
    border: none;
    border-top: 2px solid #3F3F3F;
    margin: 0;
    margin-bottom: 34px; /* 标题下线和提示文字之间的间距 */
}

/* 提示文字 */
.modalMessage {
    font-size: 16px;
    margin-bottom: 34px;
    line-height: 1.4;
    text-align: center;
}

/* 按钮区容器 */
.modalActions {
    display: flex;
    justify-content: center; /* 右对齐按钮 */
    gap: 16px; /* 按钮之间间距 30.38 px 可自行微调 */
}

.modalInput {
    height: 35px;
    /*background-color: #FFF!important;*/
    margin-top: 10px;
    margin-bottom: 0;
    border-radius: 6px;
    width: 443px;
    padding-left: 10px;
    border: 0.6px solid #383838;
    color: white;
    font-size: 12px;
}

/*.modalInput :focus {*/
/*    border: 0.6px solid #6CE4C4;*/
/*}*/

.modalInput:focus {
    border: 0.6px solid #6CE4C4;

}

/* Cancel 按钮 */
.cancelBtn {
    width: 182px; /* 参考示意图 */
    height: 48px;
    background: #333 !important;
    border: none;
    border-radius: 12px;
    color: #fff !important;
    cursor: pointer;
    font-size: 16px;
}

.cancelBtn:hover {
    background-color: #65676A !important;
}

/* Confirm 按钮 */
.confirmBtn {
    width: 182px; /* 与 Cancel 按钮保持一致也可 */
    height: 48px;
    background: #fff !important;
    border: none;
    border-radius: 12px;
    color: #000 !important;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.confirmBtn:hover {
    background-color: #DEDBE5 !important;
}


.blinkingPlaceholder {
    display: inline-block;
    /* Adjust width/height to mimic the actual grey text’s size */
    width: 80px; /* example: match what you need for your design */
    height: 18px;
    border-radius: 4px;
    vertical-align: middle;
 background-color:#2A2A2A ;
    /* The blinking animation */
    animation: blinkAnimation 1s infinite;
    margin-top: 5px;
}
.blinkingUserInfoContainer {
  display: block;
  /* match .userInfoContainer's approximate size */
  width: calc(100% - 58px);
  height: 50px; /* or however tall your row is */
  border-radius: 10px;
 background-color:#2A2A2A ;
margin-left:14px;
  animation: blinkAnimation 1.2s infinite;
    margin-bottom: 23px;
}

@keyframes blinkAnimation {
    0%, 100% {
        background-color: #2A2A2A ;
    }
    50% {
        background-color: #464646;
    }
}


@media (max-width: 1000px) {
    .rightPanel {
        width: 100%;
    }
}
@media (max-width: 600px) {
  /* 让右侧内容整体缩放并左右留出空隙 */
  .rightPanel {
    width: 100%;
  }

  /* 标题居左，去掉过大的 margin-left */
  .accountTitle {
    margin-left: 20px;
    margin-bottom: 16px;
  }

  /* 主体容器占满可视区域宽度，左右留些间距 */
  .accountPage {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    padding-inline: 20px; /* 让内部 padding 小一些 */
  }

  /* 让每个信息行在手机上纵向布局，按钮放到下方 */
  .userInfoContainer {
    /*flex-direction: column;*/
    align-items: flex-start;
    padding-left: 0;  /* 减少多余左右空隙 */
    padding-right: 0;
    gap: 8px;         /* 让文本和按钮之间留些空隙 */
    margin-bottom: 24px;
  }

  /* 让左边的图标、文字也竖排 */
  .userInfoContent {
    gap: 10px;
    margin-bottom: 0;
  }

  /* “Edit”、“Remove” 等按钮宽度自适应，避免在窄屏挤不下 */
  .editButton,
  .removeButton {
    width: auto;
    min-width: 70px; /* 给个最小宽度，防止内容被挤 */
    height: 36px;
    padding: 0 12px;
  }

  /* “Add a Payment Method” 按钮在手机上可尽量宽一些 */
  .addPaymentButton {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  /* 模态框的宽度随屏幕缩放 */
  .modalContainer {
    width: 90%;
    min-height: auto;
    padding: 20px;
  }

  /* 模态框里的输入框也自适应 */
  .modalInput {
    width: 100%;
  }

  /* 弹窗按钮在手机上可一行一个，也可左右并排看需求 */
  .modalActions {
    /*flex-direction: column;*/
    gap: 12px;
  }

  .cancelBtn,
  .confirmBtn {
    width: 100%;
  }
}